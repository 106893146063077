/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Subtitle, Title, Text, Button, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="css-wdrel9 css-42e4bw --style4 --full" anim={""} name={"uvod"} animS={"3"} border={""} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/27050/320a21db523245da9117a5c13e2d1dd4_bri=85_ove=212121x60__s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/27050/320a21db523245da9117a5c13e2d1dd4_bri=85_ove=212121x60__s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/27050/320a21db523245da9117a5c13e2d1dd4_bri=85_ove=212121x60__s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/27050/320a21db523245da9117a5c13e2d1dd4_bri=85_ove=212121x60__s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/27050/320a21db523245da9117a5c13e2d1dd4_bri=85_ove=212121x60__s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/27050/320a21db523245da9117a5c13e2d1dd4_bri=85_ove=212121x60__s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/27050/320a21db523245da9117a5c13e2d1dd4_bri=85_ove=212121x60__s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/27050/320a21db523245da9117a5c13e2d1dd4_bri=85_ove=212121x60__s=3000x_.jpeg);
    }
  
background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" anim={null} style={{"maxWidth":1390}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s5 --center mb--20 pb--0 pl--0 pr--0 pt--0" anim={"2"} animS={"5"} style={{"maxWidth":1425}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"font-weight: 700;\">NYNÍ S <span style=\"text-decoration-line: underline;\">MODELEM Y &nbsp;</span></span><br>"}>
              </Subtitle>

              <Title className="title-box fs--62 w--500 title-box--invert lh--1 mt--10" style={{"maxWidth":1016}} content={"Chcete si vyzkoušet automobilovou budoucnost o výkonu 514 koní?"}>
              </Title>

              <Text className="text-box fs--24 w--500 lh--14" style={{"maxWidth":572,"marginTop":24}} content={"<span style=\"color: var(--color-custom-1--95);\">Půjčte si Teslu Model Y s autopilotem a zrychlením 0-100 za 5 sec. ještě dnes!\n\n</span>"}>
              </Text>

              <Button className="btn-box btn-box--pbtn3 btn-box--shape5 fs--18 w--600" use={"page"} href={"/voucher/page#voucher"} style={{"marginTop":39}} content={"Objednat jedním klikem"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"sluzby"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper className="js-anim  --anim2" anim={"2"}>
              
              <Image src={"https://cdn.swbpg.com/t/27050/ad49efba48594451ae94b5d9ccbcebd9_s=350x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":149}} srcSet={"https://cdn.swbpg.com/t/27050/ad49efba48594451ae94b5d9ccbcebd9_s=350x_.png 350w"} position={null}>
              </Image>

              <Title className="title-box fs--26" style={{"marginTop":22}} content={"<span style=\"color: var(--color-supplementary);\">Bezpečnost a vlastnosti\n</span>"}>
              </Title>

              <Text className="text-box fs--16 lh--18" content={"<span style=\"color: var(--color-blend--15);\">Díky nízko posazenému bateriovému packu je Tesla Model Y jedno z nejbezpečnějších a sportovně nejvyváženějších automobilů ve své třídě.\n\n</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2" anim={"2"}>
              
              <Image src={"https://cdn.swbpg.com/t/27050/fa87ba1dd5ef4daf8963bf399ce36497_s=350x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":149}} srcSet={"https://cdn.swbpg.com/t/27050/fa87ba1dd5ef4daf8963bf399ce36497_s=350x_.png 350w"} position={null}>
              </Image>

              <Title className="title-box fs--26" style={{"marginTop":22}} content={"Pohonná jednotka (514 koní!)\n\n"}>
              </Title>

              <Text className="text-box fs--16 lh--18" content={"<span style=\"color: var(--color-blend--15);\">Pohon 4×4 přináší neskutečné zrychlení 0-100 za 5sec. a maximalní rychlost 217km/h. Baterie přináší dojezd úžasných 533km na jedno nabití.&nbsp;&nbsp;</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2" anim={"2"}>
              
              <Image src={"https://cdn.swbpg.com/t/27050/6c752d0c7d224dd28cec46d15281dbac_s=350x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":149}} srcSet={"https://cdn.swbpg.com/t/27050/6c752d0c7d224dd28cec46d15281dbac_s=350x_.png 350w"} position={null}>
              </Image>

              <Title className="title-box fs--26" style={{"marginTop":22}} content={"Autopilot a vlastnosti\n"}>
              </Title>

              <Text className="text-box fs--16 lh--18" content={"<span style=\"color: var(--color-blend--15);\">12 Ultrasonických senzorů a 8 kamer v 360 stupňovém výhledu se stará o plně autonomní řízení, které se s každou novou aktualizací stává stále chytřejší.&nbsp;&nbsp;</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" name={"citat"} style={{"backgroundColor":"#f5fafd"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim6" anim={"6"} style={{"maxWidth":1670}}>
              
              <Text className="text-box fs--30 w--400 lh--14" style={{"maxWidth":550}} content={"<span style=\"color: rgb(82, 113, 142);\"><fontsninja-text id=\"fontsninja-text-167\" class=\"fontsninja-family-2033\">Potěšte své blízké a darujte jim poukaz na zážitkovou jízdu s&nbsp;</fontsninja-text><span style=\"font-weight: bold;\"><fontsninja-text id=\"fontsninja-text-168\" class=\"fontsninja-family-2033\">Teslou Y</fontsninja-text></span><fontsninja-text id=\"fontsninja-text-167\" class=\"fontsninja-family-2033\">. A nebo rovnou pronájem. Vyberte si sami.</fontsninja-text></span>"}>
              </Text>

              <Button className="btn-box btn-box--pbtn3 btn-box--shape5 fs--18 w--600" use={"page"} href={"/voucher/page#voucher"} style={{"marginTop":39}} content={"Objednat poukaz – od 1800Kč"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"informace-1"} style={{"backgroundColor":"var(--color-custom-2)"}}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5" anim={"5"} style={{"maxWidth":""}}>
              
              <Image className="--shape3" src={"https://cdn.swbpg.com/t/27050/562cb481656b4da2b33e705861b55927_s=860x_.jpeg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/27050/562cb481656b4da2b33e705861b55927_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/27050/562cb481656b4da2b33e705861b55927_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/27050/562cb481656b4da2b33e705861b55927_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/27050/562cb481656b4da2b33e705861b55927_s=1400x_.jpeg 1400w"} position={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim4" anim={"4"} style={{"maxWidth":596}}>
              
              <Title className="title-box" style={{"maxWidth":474}} content={"Minimalistický interiér. Bude vám stačit?&nbsp;<br>"}>
              </Title>

              <Text className="text-box" content={"<span style=\"color: var(--color-blend--15);\">Naprostý převrat nastal i v interiérové části. Vše se ovládá na středovém displayi. Chcete otevřít skřínku u spolujezdce? Chcete změnit směr či sílu foukání ventilátorů? Vše skrze středový display. Plánujete pořízení? Pojdte si k nám vyzkoušet, jestli vám toto řešení sedí.</span><br>"}>
              </Text>

              <Button className="btn-box btn-box--pbtn2 btn-box--shape4 mt--20" use={"page"} href={"/voucher/page#voucher"} content={"Vyzkoušet"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"informace-1"} style={{"backgroundColor":"#f5fafd"}}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --left" anim={"5"} style={{"maxWidth":""}}>
              
              <Text className="text-box w--600" content={"<span style=\"color: var(--color-blend--50);\">Co nabízíme?&nbsp;</span><br>"}>
              </Text>

              <Title className="title-box" style={{"maxWidth":652,"marginTop":20}} content={"Chcete si auto pouze vyzkoušet? Nebo půjčit na víkend? Není problém…\n<br>"}>
              </Title>

              <Text className="text-box" content={"<span style=\"color: var(--color-blend--15);\">Chcete si vyzkoušet nejnovější technologie na trhu? Nabízíme půlhodinové, hodinové jízdy s instruktorem nebo celodenní zapůjčení. Pokud nevlastníte řidičské oprávnění, nabízíme i zážitkovou spolujízdu s instruktory, poskytnutí vozu s řidičem na svatbu, foto a video, či firemní akce – obzláštněte si i váš teambuilding.&nbsp;&nbsp;</span><br>"}>
              </Text>

              <Button className="btn-box btn-box--pbtn2 btn-box--shape4 mt--20" use={"page"} href={"/voucher/page#voucher"} content={"Koupit poukaz"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim4" anim={"4"} style={{"maxWidth":596}}>
              
              <Image className="--shape3" src={"https://cdn.swbpg.com/t/27050/3428ae2fc8414675aa46826c3622add9_s=660x_.jpeg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/27050/3428ae2fc8414675aa46826c3622add9_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/27050/3428ae2fc8414675aa46826c3622add9_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/27050/3428ae2fc8414675aa46826c3622add9_s=860x_.jpeg 860w"} position={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30" name={"informace-2"} style={{"paddingTop":64}} lightbox={true}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2" anim={"2"}>
              
              <Title className="title-box" style={{"maxWidth":""}} content={"<fontsninja-text id=\"fontsninja-text-230\" class=\"fontsninja-family-2033\">Naše modelka <span style=\"font-weight: bold;\">Y - lonka</span></fontsninja-text>"}>
              </Title>

              <Image className="--shape3" alt={"Model Y"} src={"https://cdn.swbpg.com/t/27050/0bd10aa0eb6841b6af3c16ad631a532a_e=55x36x1461x1096_s=860x_.jpeg"} sizes="(max-width: 1439px) 100vw, 1440px" style={{"maxWidth":860,"marginTop":51}} srcSet={"https://cdn.swbpg.com/t/27050/0bd10aa0eb6841b6af3c16ad631a532a_e=55x36x1461x1096_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/27050/0bd10aa0eb6841b6af3c16ad631a532a_e=55x36x1461x1096_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/27050/0bd10aa0eb6841b6af3c16ad631a532a_e=55x36x1461x1096_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/27050/0bd10aa0eb6841b6af3c16ad631a532a_e=55x36x1461x1096_s=1400x_.jpeg 1400w"} position={null}>
              </Image>

              <Image className="--shape3" alt={"Model Y"} src={"https://cdn.swbpg.com/t/27050/d710beb5c8584dca898f653555847a89_e=19x0x1547x1160_s=860x_.jpeg"} sizes="(max-width: 1439px) 100vw, 1440px" style={{"maxWidth":860}} srcSet={"https://cdn.swbpg.com/t/27050/d710beb5c8584dca898f653555847a89_e=19x0x1547x1160_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/27050/d710beb5c8584dca898f653555847a89_e=19x0x1547x1160_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/27050/d710beb5c8584dca898f653555847a89_e=19x0x1547x1160_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/27050/d710beb5c8584dca898f653555847a89_e=19x0x1547x1160_s=1400x_.jpeg 1400w"} position={null}>
              </Image>

              <Image className="--shape3" alt={"Model Y"} src={"https://cdn.swbpg.com/t/27050/580db75f46c8403ab0e1f95ec82e76f8_e=1x29x1566x1171_s=860x_.jpeg"} sizes="(max-width: 1439px) 100vw, 1440px" style={{"maxWidth":860}} srcSet={"https://cdn.swbpg.com/t/27050/580db75f46c8403ab0e1f95ec82e76f8_e=1x29x1566x1171_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/27050/580db75f46c8403ab0e1f95ec82e76f8_e=1x29x1566x1171_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/27050/580db75f46c8403ab0e1f95ec82e76f8_e=1x29x1566x1171_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/27050/580db75f46c8403ab0e1f95ec82e76f8_e=1x29x1566x1171_s=1400x_.jpeg 1400w"} position={null}>
              </Image>

              <Image className="--shape3" alt={"Model Y"} src={"https://cdn.swbpg.com/t/27050/ff33295af5ea4ce4b7ba946f750d5634_e=7x7x1566x1174_s=860x_.jpeg"} sizes="(max-width: 1439px) 100vw, 1440px" style={{"maxWidth":860}} srcSet={"https://cdn.swbpg.com/t/27050/ff33295af5ea4ce4b7ba946f750d5634_e=7x7x1566x1174_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/27050/ff33295af5ea4ce4b7ba946f750d5634_e=7x7x1566x1174_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/27050/ff33295af5ea4ce4b7ba946f750d5634_e=7x7x1566x1174_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/27050/ff33295af5ea4ce4b7ba946f750d5634_e=7x7x1566x1174_s=1400x_.jpeg 1400w"} position={null}>
              </Image>

              <Image className="--shape3" alt={"Model Y"} src={"https://cdn.swbpg.com/t/27050/de7ff87955124ea9859514b56d5e341c_e=26x12x1574x787_s=860x_.jpeg"} sizes="(max-width: 1439px) 100vw, 1440px" style={{"maxWidth":860}} srcSet={"https://cdn.swbpg.com/t/27050/de7ff87955124ea9859514b56d5e341c_e=26x12x1574x787_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/27050/de7ff87955124ea9859514b56d5e341c_e=26x12x1574x787_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/27050/de7ff87955124ea9859514b56d5e341c_e=26x12x1574x787_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/27050/de7ff87955124ea9859514b56d5e341c_e=26x12x1574x787_s=1400x_.jpeg 1400w"} position={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pt--30" name={"paticka"} style={{"paddingBottom":18,"backgroundColor":"rgba(32, 32, 32, 1)"}}>
          
          <ColumnWrap className="column__flex --left el--2 flex--bottom" columns={"2"} fullscreen={false}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box" content={"<span style=\"color: var(--color-custom-1--95);\">Kontakt\n</span>"}>
              </Title>

              <Text className="text-box fs--16" content={"<fontsninja-text id=\"fontsninja-text-258\" class=\"fontsninja-family-2033\">+420 555 222 451<br></fontsninja-text>BESKYD servis spol. s r.o.<br>Revoluční 149&nbsp;<br>739 11 Frýdlant n.O.<br>IČ 27800814<br>DIČ CZ27800814<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Text className="text-box fs--16" style={{"marginTop":0}} content={"www.botesla.cz<br>"}>
              </Text>

              <Text className="text-box fs--16" style={{"marginTop":0}} content={"boteslacz@gmail.com<br>"}>
              </Text>

              <Text className="text-box fs--16" style={{"marginTop":7}} content={"<span style=\"color: var(--color-blend--15);\">GDPR | <a href=\"https://cdn.swbpg.com/d/27050/vseobecne-obchodni-podminky.pdf\">Obchodní podmínky</a></span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}